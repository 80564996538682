<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">账号管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">账号列表（人力）</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ lastPageData.auditState == '10' ? '编辑' : '审核' }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="ruleForm" label-width="7rem" class="form" :rules="rules" :model="ruleForm">
              <el-row>
                <el-col :span="8">
                  <div class="fromTitle">账号信息</div>
                  <el-form-item label="账号姓名" prop="adminName">
                    <el-input type="text" v-model="ruleForm.adminName" size="small" clearable placeholder="请输入账号姓名" />
                  </el-form-item>
                  <el-form-item label="使用人姓名" prop="fullname">
                    <el-input type="text" v-model="ruleForm.fullname" size="small" clearable placeholder="请输入使用人姓名" />
                  </el-form-item>
                  <el-form-item label="使用人电话" prop="mobile">
                    <el-input type="text" v-model="ruleForm.mobile" size="small" clearable placeholder="请输入使用人电话" />
                  </el-form-item>
                  <el-form-item label="安全权限">
                    <el-checkbox v-model="ruleForm.adminSensitive">身份证脱敏</el-checkbox>
                    <el-checkbox v-model="ruleForm.mobileSensitive">手机号脱敏</el-checkbox>
                    <el-checkbox v-model="ruleForm.videoIsMark">视频预览水印</el-checkbox>
                  </el-form-item>
                  <el-form-item label="账号权限" prop="authorizationScope">
                    <el-select clearable size="small" v-model="ruleForm.authorizationScope" placeholder="请选择账号权限">
                      <el-option v-for="item in permissionsList" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="角色">
                    <el-select clearable size="small" v-model="ruleForm.roleId" placeholder="请选择角色" disabled>
                      <el-option v-for="item in roleIdList" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                  <div class="fromTitle">基本信息</div>
                  <el-form-item label="公司名称">
                    <el-input type="text" v-model="ruleForm.compName" size="small" clearable placeholder="请输入公司名称"
                      disabled />
                  </el-form-item>
                  <el-form-item label="使用代码">
                    <el-input type="text" v-model="ruleForm.compNo" size="small" clearable placeholder="请输入使用代码"
                      disabled />
                  </el-form-item>
                  <el-form-item label="行政区划">
                    <el-cascader v-model="ruleForm.areaId" :options="areatreeList" :props="propsarea" disabled clearable
                      filterable size="small"></el-cascader>
                  </el-form-item>
                  <el-form-item label="法人姓名">
                    <el-input type="text" v-model="ruleForm.compLegal" size="small" clearable placeholder="请输入法人姓名"
                      disabled />
                  </el-form-item>
                  <el-form-item label="法人身份证号">
                    <el-input type="text" v-model="ruleForm.compIdcard" size="small" clearable placeholder="请输入法人身份证号"
                      disabled />
                  </el-form-item>
                  <el-form-item label="联系人姓名">
                    <el-input type="text" v-model="ruleForm.compUser" size="small" clearable placeholder="请输入联系人姓名"
                      disabled />
                  </el-form-item>
                  <el-form-item label="联系人电话">
                    <el-input type="text" v-model="ruleForm.compPhone" size="small" clearable placeholder="请输入联系人电话"
                      disabled />
                  </el-form-item>
                  <el-form-item label="经营范围">
                    <el-input type="textarea" resize="none" show-word-limit maxlength="200" rows="3"
                      v-model="ruleForm.compBusiness" clearable disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16" style="padding-left: 60px;">
                  <div class="fromTitle">证照信息</div>
                  <el-image v-for="(item, index) in ruleForm.fileKeys" :key="index" style="width: 160px; height: 90px"
                    :src="item" :preview-src-list="ruleForm.fileKeys">
                  </el-image>
                </el-col>
              </el-row>
            </el-form>
            <div class="btn-box flexcc">
              <el-button size="small" @click="$router.back()" class="bgc-bv">取 消</el-button>
              <template v-if="lastPageData.auditState == '10'">
                <el-button size="small" class="bgc-bv" @click="doEditSave" >编 辑</el-button>
              </template>
              <template v-else>
                <el-button size="small" class="bgc-bv" @click="auditingLoadingOk('10')">通 过</el-button>
                <el-button size="small" class="bgc-bv" @click="changeAuditing">不通过</el-button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 审核备注 - 弹框 -->
    <el-dialog title="审核备注" :visible.sync="auditingLoadingData.dialogVisible" width="440px"
      :before-close="auditingLoadingClose">
      <el-form :model="auditingLoadingData" style="width: 100%" :rules="auditingLoadingDataRules"
        ref="auditingLoadingData">
        <el-form-item label="备注：" label-width="100px" prop="remark">
          <el-input type="textarea" v-model="auditingLoadingData.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" size="small" round @click="auditingLoadingOk('20')">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "accountManagement_accountEditHR",
  components: {},
  data() {
    return {
      // 上个页面的数据
      lastPageData: {
        adminId: "", // 账号id
        auditState: "", // 审核状态:10已通过;20已驳回;00待审核
        authorizationScope: "", // 账号权限(这个字段老详情接口和新详情接口都没有返，自己从上个页面带)
      },
      // 基本信息
      ruleForm: {
        // 账号信息
        adminId: "", // 账号id
        compId: "", // 机构id
        realRoleId: "", // 非基础角色主键
        adminName: "", // 角色名称
        fullname: "", // 使用人姓名
        mobile: "", // 使用人电话
        adminSensitive: "", // 身份证脱敏
        mobileSensitive: "", // 手机号脱敏
        videoIsMark: "", // 视频预览水印
        authorizationScope: "", // 账号权限
        roleId: "", // 角色
        trainTypeIdRange: "", // 培训类型
        // 基础信息
        compName: "", // 公司名称
        compNo: "", // 信用代码
        areaId: "", // 行政区划
        compLegal: "", // 法人姓名
        compIdcard: "", // 法人身份证
        compUser: "", // 联系人姓名
        compPhone: "", // 联系人电话
        compBusiness: "", // 经营范围
        // 证照信息
        fileKeys: [], // 文件数据集合
      },
      // 数据校验
      rules: {
        adminName: [
          { required: true, trigger: "change", message: "关联单位不能为空" },
        ],
      },
      // 账号权限
      permissionsList: [
        {
          value: 10,
          label: "当前账户",
        },
        {
          value: 20,
          label: "当前公司",
        },
        {
          value: 30,
          label: "当前公司及其子公司",
        },
        {
          value: 40,
          label: "当前账号及下级账号",
        },
      ],
      // 角色 - 下拉数据
      roleIdList: [],
      // 行政区划 - 下拉数据
      areatreeList: [],
      // 审核弹框数据
      auditingLoadingData: {
        dialogVisible: false, // 弹框状态
        remark: "", // 审核备注
      },
      // 数据校验
      auditingLoadingDataRules: {
        remark: [
          { required: true, trigger: "blur", message: "请输入备注" },
        ],
      },
    };
  },
  created() {
    this.lastPageData = {
      ...this.lastPageData,
      ...this.$route.query
    }
    this.ruleForm.authorizationScope = this.$route.query.authorizationScope;
    this.getRoleIdList();
    this.getAreatree();
    this.getDetail();
  },
  computed: {},
  methods: {
    // 获取角色 - 下拉数据
    getRoleIdList() {
      this.$post("/sys/per/role/basal")
        .then((res) => {
          this.roleIdList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取详情
    getDetail() {
      // 老详情接口 - 用来回显账号信息
      this.$post("/sys/admin/adminId", { adminId: this.$route.query.adminId }).then((res) => {
        this.ruleForm.adminId = res.data.adminId; // 账号id
        this.ruleForm.compId = res.data.compId;// 机构id
        this.ruleForm.realRoleId = res.data.realRoleId;// 非基础角色主键
        this.ruleForm.adminName = res.data.adminName; // 角色名称
        this.ruleForm.fullname = res.data.fullname;// 使用人姓名
        this.ruleForm.mobile = res.data.mobile;// 使用人电话
        this.ruleForm.adminSensitive = res.data.adminSensitive; // 身份证脱敏
        this.ruleForm.mobileSensitive = res.data.mobileSensitive; // 手机号脱敏
        this.ruleForm.videoIsMark = res.data.videoIsMark; // 视频预览水印
        this.ruleForm.authorizationScope = res.data.authorizationScope; // 账号权限
        this.ruleForm.roleId = res.data.roleId; // 角色
        this.ruleForm.trainTypeIdRange = res.data.trainTypeIdRange; // 培训类型
      });
      // 人力详情接口 - 用来回基础信息、证照信息
      this.$post("/hr/admin/getCompInfo", { adminId: this.$route.query.adminId }).then((res) => {
        this.ruleForm.compName = res.data.compName; // 公司名称
        this.ruleForm.compNo = res.data.compNo; // 信用代码
        this.ruleForm.areaId = res.data.areaId; // 行政区划
        this.ruleForm.compLegal = res.data.compLegal; // 法人姓名
        this.ruleForm.compIdcard = res.data.compIdcard; // 法人身份证
        this.ruleForm.compUser = res.data.compUser; // 联系人姓名
        this.ruleForm.compPhone = res.data.compPhone; // 联系人电话
        this.ruleForm.compBusiness = res.data.compBusiness; // 经营范围
        this.ruleForm.fileKeys = res.data.fileKeys; // 证照信息
      });
    },
    // 获取行政区划
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取行政区划
    getAreatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 审核 - 弹框打开
    changeAuditing() {
      this.auditingLoadingData.dialogVisible = true;
    },
    // 审核 - 弹框关闭
    auditingLoadingClose() {
      this.auditingLoadingData = this.$options.data().auditingLoadingData;
    },
    // 审核 - 弹框确定
    auditingLoadingOk(type) {
      if(type == '10'){
        let params = {
          adminId: this.ruleForm.adminId, // 账号id
          auditState: type, // 审核状态:10已通过;20已驳回;00待审核
          remark: this.auditingLoadingData.remark,// 申请备注
        };
        this.$post("/hr/admin/audit", params)
          .then((res) => {
            this.auditingLoadingClose();
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.$router.back();
          })
          .catch(() => {
            return;
          });
      } else {
        this.$refs.auditingLoadingData.validate((valid) => {
        if (valid) {
          let params = {
            adminId: this.ruleForm.adminId, // 账号id
            auditState: type, // 审核状态:10已通过;20已驳回;00待审核
            remark: this.auditingLoadingData.remark,// 申请备注
          };
          this.$post("/hr/admin/audit", params)
            .then((res) => {
              this.auditingLoadingClose();
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.back();
            })
            .catch(() => {
              return;
            });
          }
        });
      }
    },
    // 编辑
    doEditSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            adminId: this.ruleForm.adminId, // 账号id
            compId: this.ruleForm.compId,// 机构id
            realRoleId: this.ruleForm.realRoleId,// 非基础角色主键
            adminName: this.ruleForm.adminName, // 角色名称
            fullname: this.ruleForm.fullname,// 使用人姓名
            mobile: this.ruleForm.mobile,// 使用人电话
            adminSensitive: this.ruleForm.adminSensitive, // 身份证脱敏
            mobileSensitive: this.ruleForm.mobileSensitive, // 手机号脱敏
            videoIsMark: this.ruleForm.videoIsMark, // 视频预览水印
            authorizationScope: this.ruleForm.authorizationScope, // 账号权限
            roleId: this.ruleForm.roleId, // 角色
            trainTypeIdRange: this.ruleForm.trainTypeIdRange, // 培训类型
          };
          this.$post("/sys/admin/modify", params)
            .then((res) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.back();
            })
            .catch(() => {
              return;
            });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.form {
  .fromTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }
}

.btn-box {
  margin-bottom: 10px;
}
</style>